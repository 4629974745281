<template>
  <Layout>
    <div class="app-pagelist">
      <div class="tool">
        <a-form layout="inline">
          <a-button @click="add" type="primary">
            <a-icon type="file-add" /> 新建页面
          </a-button>
        </a-form>

        <a-button @click="share(0)" type="link">
          <a-icon type="mobile" /> 分享店铺
        </a-button>
      </div>
      <div class="table-wrap">
        <a-table :columns="columns" :data-source="list" :pagination="false">
          <template slot="active" slot-scope="name, record">
            <div>
              <a-tag v-if="record.active" color="pink">首页</a-tag>
              <a v-else @click="setActive(record.id)" class="btnSetActive">
                设为首页
              </a>
            </div>
          </template>
          <template slot="name" slot-scope="name, record">
            <editable-cell
              :text="name"
              @change="updateName(record.id, $event)"
            />
          </template>
          <span slot="createdAt" slot-scope="time">{{
            time | formatDate
          }}</span>
          <span slot="updatedAt" slot-scope="time">{{
            time | formatDate
          }}</span>
          <span slot="action" slot-scope="_, record">
            <router-link :to="`/page/detail/${record.id}`"
              >开始编辑</router-link
            >
            <a-divider type="vertical" />
            <a @click="copy(record.id)">复制</a>
            <a-divider type="vertical" />
            <a @click="share(record.id)">分享</a>
            <a-divider type="vertical" />
            <a style="color:#f5222d" @click="del(record.id, record.name)"
              >删除</a
            >
          </span>
        </a-table>
      </div>

      <!-- 分享二维码 -->
      <a-modal
        :destroyOnClose="true"
        :footer="null"
        :visible="shareDialogVisible"
        @cancel="shareDialogVisible = false"
        class="share-dialog-wrap"
      >
        <div class="title">
          右键保存小程序码即可分享
        </div>
        <img :src="shareInfo.qrcode" class="qrcode" />
        <div class="row">
          <span class="label">AppId：</span>
          <a-input disabled :value="store.id" />
          <a-button @click="copyText(store.id)">复制</a-button>
        </div>
        <div class="row">
          <span class="label">路径：</span>
          <a-input disabled :value="shareInfo.path" />
          <a-button @click="copyText(shareInfo.path)">复制</a-button>
        </div>
        <div class="row">
          <a-icon type="info-circle" />
          提示：请先装修首页并发布，否则会显示「页面不存在」的错误
        </div>
      </a-modal>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import { mapState } from "vuex";

import EditableCell from "@/components/editable-cell.vue";
const PageSvc = require("@/service/page");

import wxacodeMixin from "@/mixins/wxacode";

const columns = [
  {
    title: "",
    dataIndex: "active",
    key: "active",
    scopedSlots: { customRender: "active" },
    width: 100,
    align: "right"
  },
  {
    title: "页面名称",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" }
  },
  {
    title: "创建时间",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 180,
    scopedSlots: { customRender: "createdAt" }
  },
  {
    title: "修改时间",
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 180,
    scopedSlots: { customRender: "updatedAt" }
  },
  {
    title: "操作",
    key: "action",
    width: 240,
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "PageList",

  data() {
    return {
      columns,
      list: []
    };
  },

  computed: {
    ...mapState({
      store: state => state.store
    })
  },

  mixins: [wxacodeMixin],

  methods: {
    async add() {
      try {
        const { id } = await PageSvc.add();

        this.$router.push({ path: `/page/detail/${id}` });
      } catch (err) {
        console.error(err);
        this.$message.error("添加页面失败，错误：" + err.message);
      }
    },

    async copy(id) {
      try {
        await PageSvc.copy(id);

        this.list = await PageSvc.list();
      } catch (err) {
        console.error(err);
        this.$message.error("复制失败，错误：" + err.message);
      }
    },

    async share(id) {
      // 分享店铺
      if (!id) {
        const { qrcode } = this.store;
        const path = "pages/index/index";

        this.shareInfo = {
          path,
          qrcode
        };

        this.shareDialogVisible = true;
        return;
      }

      // 分享页面
      const path = `plugin-private://wxd14f33852509c129/pages/page/index?pageId=${id}`;
      await this.sharePath(path);
    },

    async del(id, name) {
      this.$confirm({
        title: "操作提示",
        content: `确认删除页面「${name}」吗？`,

        onOk: () => {
          this._delete(id);
        },
        onCancel() {}
      });
    },

    async _delete(id) {
      try {
        await PageSvc.del(id);

        this.list = this.list.filter(x => x.id != id);
      } catch (err) {
        console.error(err);
        this.$message.error("删除失败，错误：" + err.message);
      }
    },

    async updateName(id, name) {
      try {
        await PageSvc.updateName(id, name);

        const idx = this.list.findIndex(x => x.id == id);
        this.$set(this.list, idx, {
          ...this.list[idx],
          name
        });
      } catch (err) {
        console.error(err);
        this.$message.error("修改页面名称失败，错误：" + err.message);
      }
    },

    async setActive(id) {
      this.$confirm({
        title: "操作提示",
        content: "确认覆盖首页内容吗？此操作无法撤消，请谨慎操作。",

        onOk: () => {
          this._setActive(id);
        },
        onCancel() {}
      });
    },
    async _setActive(id) {
      try {
        await PageSvc.setActive(id);

        const list = this.list.map(x => ({
          ...x,
          active: x.id == id
        }));
        this.list = list;
      } catch (err) {
        console.error(err);
        this.$message.error("设为首页失败，错误：" + err.message);
      }
    }
  },

  async mounted() {
    try {
      const list = await PageSvc.list();
      this.list = list;
    } catch (err) {
      console.error(err);
      this.$message.error("未能正确加载页面，错误：" + err.message);
    }
  },

  components: {
    Layout,
    EditableCell
  }
};
</script>

<style lang="scss">
.app-pagelist {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }
  .table-wrap {
    background: #fff;

    .ant-table-row .btnSetActive {
      display: none;
    }

    .ant-table-row:hover .btnSetActive {
      display: inherit;
    }
  }
}
</style>
